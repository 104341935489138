export const createImageOverlayBox = (index, data = {}) => {
    console.log(data);
      const inputTemplate = /* html */`
        <div class="d-flex align-items-center">
        <button type="button" class="btn btn-link" data-filter="overlayBox" data-button="flip-element-box" title="Flip Element"></button>
        <button type="button" class="btn btn-link" data-filter="overlayBox" data-button="delete-text-box" title="Remove text box"></button>
        <textarea class="form-control meme-text" type="text" data-input="text" autocomplete="off" rows="1" placeholder="${`Name this Element`}">Element #${index} sads</textarea>
          <div class="d-flex align-items-center pe-2">
            <button type="button" class="btn btn-secondary settings-button" data-button="overlayBox" title="Settings"></button>
          </div>
        </div>
    
        <div class="p-2" data-section="overlayBox" hidden>
          <div class="row g-2">
            <div class="col-3 mb-3">
              <label for="xInput_${index}" class="mb-1 d-block text-truncate">X Position:</label>
              <input class="form-control" data-filter="overlayBox" type="number" value="${data.x}" data-input="x" id="xInput_${index}">
            </div>
    
            <div class="col-3 mb-3">
              <label for="yInput_${index}" class="mb-1 d-block text-truncate">Y Position:</label>
              <input class="form-control" data-filter="overlayBox" type="number" value="${data.y}" data-input="y" id="yInput_${index}">
            </div>
  
            <div class="col-3 mb-3">
              <label for="width_${index}" class="mb-1 d-block text-truncate">Size:</label>
              <input class="form-control" data-filter="overlayBox" type="number" value="${data.width}" data-input="width" id="width_${index}">
            </div>
            <div class="col-3 mb-3">
            <label for="rotate_${index}" class="mb-1 d-block text-truncate">Rotate:</label>
            <input class="form-control" data-filter="overlayBox" type="number" value="${data.rotate}" data-input="rotateOverlay" id="rotate_${index}">
          </div>
    
            <div class="col-12">
            <div class="move-text-actions mb-3">
              <button type="button" class="btn btn-secondary" data-format="overlay" data-move="offsetY" data-sign="-" aria-label="Up"></button>
              <button type="button" class="btn btn-secondary" data-format="overlay" data-move="offsetX" data-sign="+" aria-label="Right"></button>
              <button type="button" class="btn btn-secondary" data-format="overlay" data-move="offsetY" data-sign="+" aria-label="Down"></button>
              <button type="button" class="btn btn-secondary" data-format="overlay" data-move="offsetX" data-sign="-" aria-label="Left"></button>
            </div>
          </div>
          </div>
        </div>
      `;
  
    const fragment = document.createDocumentFragment();
    const div = document.createElement('div');
    div.className = 'bg-light border shadow-sm mb-3 rounded image-overlay-box';
    div.setAttribute('data-section', 'overlayBox');
    div.setAttribute('data-index', index);
    div.innerHTML = inputTemplate;
  
  // Event listener to toggle settings visibility
    const settingsButton = div.querySelector('[data-button="overlayBox"]');
    settingsButton.addEventListener('click', () => {
      const settingsSection = div.querySelector('[data-section="overlayBox"]');
      settingsSection.hidden = !settingsSection.hidden;
    });
  
    div.querySelectorAll('input').forEach(input => {
      input.value = data[input.dataset.input];
    });
  
    return fragment.appendChild(div);
  };