import timmiAvp from "../assets/TimmiAVP.png";
import timmiHead  from "../assets/timmi-head.png";

const avp = new Image();
avp.src = timmiAvp;
const head = new Image();
head.src = timmiHead;

export const drawCanvas = (image, canvas, ctx, textOptions = [], pngOptions = []) => {

  if (image == null) {
    return;
  }
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  if (typeof image === 'string') { // Assume it's a color
    ctx.fillStyle = image;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  } else {
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
  }

  textOptions.forEach(function (item, index) {
    ctx.save();

    ctx.font = `${item.fontWeight} ${item.fontSize}px ${item.font}`;
    ctx.fillStyle = item.fillColor;
    ctx.textAlign = item.textAlign;
    ctx.strokeStyle = item.shadowColor;

    const multiplier = index + 1;
    const lineHeight = ctx.measureText('M').width + item.fontSize / 2;
    const xPos = canvas.width / 2;
    const shadowBlur = item.shadowBlur;
    const text = item.allCaps === true ? item.text.toUpperCase() : item.text;
    const textLines = text.split('\n');

    if (shadowBlur !== 0) {
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 0;
      ctx.shadowBlur = shadowBlur;
      ctx.shadowColor = item.shadowColor;
    }
    ctx.translate(xPos + item.offsetX, lineHeight * multiplier + item.offsetY);
    ctx.rotate(item.rotate * Math.PI / 180);
    // first draw each line with shadow
    textLines.forEach((text, index) => ctx.fillText(text, 0, index * lineHeight));
    // since shadows of multiline text may be drawn over letters of neighbour lines
    // (when shadow blur is big enough), re-draw text without shadows.
    ctx.shadowBlur = 0;
    textLines.forEach((text, index) => ctx.fillText(text, 0, index * lineHeight));
    if (item.borderWidth > 0) {
      ctx.lineWidth = item.borderWidth;
      textLines.forEach((text, index) => ctx.strokeText(text, 0, index * lineHeight));
    }
    ctx.restore();
  });

  pngOptions.forEach((png) => {
    let img;
    if(png.type === "avp"){
      img = avp
    }else if(png.type ==="timmi"){
      img = head;
    }
    ctx.save(); // Save the current context state

    // Calculate the center of the image
    const centerX = png.x + png.width / 2;
    const centerY = png.y + png.height / 2;
  
    // Translate to the center of the image
    ctx.translate(centerX, centerY);

    // Flip horizontally if needed
    if (png.flipped) {
      ctx.scale(-1, 1);
    }
  
    // Rotate the canvas context
    ctx.rotate(png.rotateOverlay * Math.PI / 180);
  
    // Draw the image, offsetting it so that its center aligns with the canvas context's origin
    ctx.drawImage(img, -png.width / 2, -png.height / 2, png.width, png.height);
  
    ctx.restore(); // Restore the context state
  });
};

// Assuming pngOptions is already defined and initialized as an empty array

